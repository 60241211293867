import React, { useState, useEffect } from 'react';
import Swiper, { EffectCoverflow, Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
import axios from 'axios';
import "../css/style.css";
import "../css/swiper-bundle.min.css";
import placeholder from "../images/placeholder.png";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const EventList = ({ data, pageContext, path }) => {
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const post = data.allWordpressPage.edges[0].node;

  const metatitle = post.acf.meta_title;
  const metadescription = post.acf.meta_description;
  const focus_keyphrase = post.acf.focus_keyphrase;
  const canonical_url = `https://theworldgrad.com${path}`;

  Swiper.use([EffectCoverflow, Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);
  
  const formatToISO = (dateString) => {
    if (!dateString) return null;
  
    // Handle format like '2024-November-26 2:00:00 PM'
    const match = dateString.match(
      /^(\d{4})-([a-zA-Z]+)-(\d{2}) (\d{1,2}):(\d{2}):(\d{2}) (AM|PM)$/
    );
    if (match) {
      const [_, year, monthName, day, hours, minutes, seconds, period] = match;
  
      // Convert month name to numeric month
      const month = new Date(`${monthName} 1, ${year}`).getMonth() + 1;
  
      // Adjust hours for AM/PM
      let formattedHours = parseInt(hours, 10);
      if (period === 'PM' && formattedHours < 12) formattedHours += 12;
      if (period === 'AM' && formattedHours === 12) formattedHours = 0;
  
      // Return ISO string
      return `${year}-${String(month).padStart(2, '0')}-${day}T${String(formattedHours).padStart(2, '0')}:${minutes}:${seconds}`;
    }
  
    return dateString; // Return original if not matched
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const query = `
          query {
            events {
              edges {
                node {
                  id
                  databaseId
                  title
                  slug
                  eventsPost {
                    eventDate
                    eventEndDate
                  }
                  featuredImage {
                    node {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        `;

        const response = await axios.post('https://theworldgrad.wpengine.com/graphql', { query });
        const events = response.data.data.events.edges.map(edge => edge.node);

        const now = new Date();
        const past = [];
        const future = [];
        
        // Utility function to format a date string to ISO format
       
        
        events.forEach((event) => {
          // Format eventDate (start date)
          const startDate = formatToISO(event.eventsPost.eventDate);
        
          // Format endDate, fallback to eventDate if not present
          const endDate = formatToISO(event.eventsPost.eventEndDate) || startDate;
        
          // Parse formatted dates
          const startEventDate = startDate ? new Date(startDate) : null;
          const endEventDate = endDate ? new Date(endDate) : null;
        
          // Validate both dates
          if (!startEventDate || isNaN(startEventDate.getTime())) {
            console.error('Invalid start date:', event.eventsPost.eventDate, 'for event:', event.slug);
            return;
          }
        
          if (!endEventDate || isNaN(endEventDate.getTime())) {
            console.error('Invalid end date:', event.eventsPost.eventEndDate, 'for event:', event.slug);
            return;
          }
        
          // Use endEventDate for comparison
          if (endEventDate < now) {
            past.push(event);
          } else {
            future.push(event);
          }
        });
        


        const sortedFuture = future.length > 9 
        ? future.sort((a, b) => new Date(a.eventsPost.eventDate) - new Date(b.eventsPost.eventDate)).slice(0, 9)
        : future.sort((a, b) => new Date(a.eventsPost.eventDate) - new Date(b.eventsPost.eventDate));
      
      const sortedPast = past.length > 9 
        ? past.sort((a, b) => new Date(b.eventsPost.eventDate) - new Date(a.eventsPost.eventDate)).slice(0, 9)
        : past.sort((a, b) => new Date(b.eventsPost.eventDate) - new Date(a.eventsPost.eventDate));
      

        setFutureEvents(sortedFuture);
        setPastEvents(sortedPast);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    if (!loading) {
      if (futureEvents.length > 0) {
        new Swiper('.swiper-future-events', {
          // Future events swiper config
          centeredSlides: false,
          loop: false,
          slidesPerView: 1,
          spaceBetween: 0,
          autoplay: { delay: 7000, disableOnInteraction: false },
          pagination: { el: '.swiper-pagination-future-events', clickable: true },
          navigation: {
            nextEl: '.swiper-next-future-events',
            prevEl: '.swiper-prev-future-events',
          },
          breakpoints: {
            1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 20 },
            991: { slidesPerGroup: 2, slidesPerView: 2 },
            768: { slidesPerGroup: 1, slidesPerView: 1 },
          },
        });
      }
  
      if (pastEvents.length > 0) {
        new Swiper('.swiper-past-events', {
          // Past events swiper config
          centeredSlides: false,
          loop: false,
          slidesPerView: 1,
          spaceBetween: 0,
          autoplay: { delay: 7000, disableOnInteraction: false },
          pagination: { el: '.swiper-pagination-past-events', clickable: true },
          navigation: {
            nextEl: '.swiper-next-past-events',
            prevEl: '.swiper-prev-past-events',
          },
          breakpoints: {
            1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 20 },
            991: { slidesPerGroup: 2, slidesPerView: 2 },
            768: { slidesPerGroup: 1, slidesPerView: 1 },
          },
        });
      }
    }
  }, [loading, futureEvents, pastEvents]);
  

  const renderEventSlide = (event) => {

    const eventStartDate = new Date(formatToISO(event.eventsPost.eventDate));
    const eventEndDate = new Date(
      formatToISO(event.eventsPost.eventEndDate || event.eventsPost.eventDate)
    );
    

    
    // Validate dates
    if (isNaN(eventStartDate.getTime()) || (eventEndDate && isNaN(eventEndDate.getTime()))) {
      console.error('Invalid date:', event.slug);
      return null;
    }
  
 
    const formatDate = (date) => {
      const day = date.getDate();
      const nth = ["th", "st", "nd", "rd"][
        (day % 10 > 3 || Math.floor((day % 100) / 10) === 1) ? 0 : day % 10
      ];
      const month = date.toLocaleString("default", { month: "short" });
      return { day, nth, month };
    };
  
    const startFormatted = formatDate(eventStartDate);
    const endFormatted = event.eventsPost.eventEndDate ? formatDate(eventEndDate) : null;
  

    const eventDateClass = `event-date ${event.eventsPost.eventEndDate ? 'event-end-date' : ''}`;
  
    return (
      <div key={event.slug} className="swiper-slide">
        <a href={`/event-detail/?id=${event.databaseId}`} className="d-flex h-100">
          <div className="event-sec">
            <div className="event-img-wrap">
              <img
                src={event.featuredImage?.node?.sourceUrl || placeholder}
                alt={event.title}
              />
              <p className={eventDateClass}>
                <span>
                  {startFormatted.day}
                  <span className="nth-lowercase">{startFormatted.nth}</span> {startFormatted.month}
                  {event.eventsPost.eventEndDate && (
                    <>
                      {" "} - {endFormatted.day}
                      <span className="nth-lowercase">{endFormatted.nth}</span> {endFormatted.month}
                    </>
                  )}
                </span>
              </p>
            </div>
            <div className="event-content-wrap p-3">
              <h4
                className="event-title"
                dangerouslySetInnerHTML={{ __html: event.title }}
              ></h4>
            </div>
          </div>
        </a>
      </div>
    );
  };
  

  return (
    <Layout>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        {focus_keyphrase && <meta name="keywords" content={focus_keyphrase} />}
        <link rel="canonical" href={canonical_url} />
        {post.acf.dynamic_schema && (
          <script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>
        )}
      </Helmet>

      <section className="event-listing-sec pt-5 pb-5">
        <div className="container">
          <h1 className="event-main-title text-center mb-5 h2">{post.acf.label_of_list_of_event_items}</h1>
          {loading ? (
      <div className="loading-placeholder">Loading events...</div> // Loading placeholder
    ) : futureEvents.length === 0 ? (
      <div className="col-12 position-relative">
        <p className="text-center">
          No upcoming events found. Stay tuned for exciting updates!
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>
    ) : (
          <div className="swiper-container swiper-future-events">
            <div className="swiper-wrapper mb-5">
              {futureEvents.map(renderEventSlide)}
            </div>
          
            <div className="swiper-pagination swiper-pagination-future-events"></div>

          </div>

)}

          <h1 className="event-main-title text-center mb-5 h2">{post.acf.label_of_list_of_event_items_past}</h1>
         
          {loading ? (
      <div className="loading-placeholder">Loading events...</div> // Loading placeholder
    ) : pastEvents.length === 0 ? (
      <div className="col-12 position-relative">
        <p className="text-center">
          No past events found. Stay tuned for exciting updates!
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>
    ) : (
  <div className="swiper-container swiper-past-events">
    <div className="swiper-wrapper mb-5">
      {pastEvents.map(renderEventSlide)}
    </div>
    <div className="swiper-pagination swiper-pagination-past-events  "></div>
  </div>

)}
        </div>
      </section>
    </Layout>
  );
};


export const query = graphql`
  query EventQuery {
    allWordpressPage(filter: { wordpress_id: { in: 2499 } }) {
      edges {
        node {
          id
          title
          featured_media {
            source_url
          }
          acf {
            register_link {
              title
              url
              target
            }
            meta_title
            meta_description
            focus_keyphrase
            canonical_url
            dynamic_schema
            banner_label
            label_of_list_of_event_items
            label_of_list_of_event_items_past
            webinar_start_date
            webinar_start_on
          }
        }
      }
    }
  }
`;

export default EventList;
